import React from "react"

import Layout from "../components/common/layout/layout"
import Navigation from "../components/common/navigation/navigation"

import Banner from "../components/sections/banner"
import Abstract from "../components/sections/abstract"
import Submission from "../components/sections/submission"
import Footer from "../components/sections/footer"
import ImportantDates from "../components/sections/dates"
import Presentation from "../components/sections/presentation"
import Registration from "../components/sections/registration"
import PosterAndVideos from "../components/sections/poster-and-videos"

const Guides = () => (
  <Layout>
    <Navigation/>
    <Banner title="Author's Guide"/>
    <Abstract />
    <PosterAndVideos />
    <Submission />
    <ImportantDates background={"bg-light"} />
    <Presentation />
    <Registration />
    <Footer />
  </Layout>
)

export default Guides
