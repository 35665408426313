import React from "react"
import SectionWrapper from "./section-wrapper"
import SectionTitle from "./section-title"

import CardListItem from "./card-list-item"

const ImportantDates = ({ spacing, background }) => {
  // List of important dates
  const dates = [
    {
      title: "1st Call for Extended Abstracts",
      date: "December 21, 2020",
    },
    {
      title: "2nd Call for Extended Abstracts",
      date: "January 10, 2021",
    },
    {
      title: "Extended Abstracts Submission Deadline",
      date: "March 15, 2021",
    },
    {
      title: "Notification of Acceptance",
      date: "March 24, 2021",
    },
    {
      title: "Submission of Revised Extended Abstracts",
      date: "March 29, 2021",
    },
    {
      title: "Submission of Posters and Videos",
      date: "March 29, 2021",
    },
    {
      title: "Presentations",
      date: "April 7, 2021",
    },
  ]
  return (
    <div className={`flex items-center ${background}`} id="important-dates">
      <SectionWrapper padding={spacing || `px-6 md:px-16 py-16`}>
        <SectionTitle textSize="text-2xl md:text-3xl">Important Dates</SectionTitle>
        <div className="mt-4 flex flex-wrap items-stretch">
          {dates.map((date) => (
            <CardListItem width="w-full md:w-1/2 lg:w-1/3">
              <p className="text-sm font-medium text-primary-light">
                {date.title}
              </p>
              <h2 className="text-lg md:text-xl font-semibold">{date.date}</h2>
            </CardListItem>
          ))}
        </div>
      </SectionWrapper>
    </div>
  )
}

export default ImportantDates
