import React from "react"
import SectionWrapper from "./section-wrapper"
import SectionTitle from "./section-title"

import CardListItem from "./card-list-item"

const Submission = () => (
  <div className="flex items-center" id="submission">
    <SectionWrapper padding="px-6 md:px-16 py-16">
      <SectionTitle textSize="text-3xl">Submission Policy</SectionTitle>
      <div className="mt-4 flex flex-wrap items-stretch">
        <CardListItem width="w-full">
          <p>
            In order to provide opportunities for as many as possible to present
            their work, authors <b>may not submit more than two abstracts</b>{" "}
            where they are the lead presenter.
          </p>
        </CardListItem>
        <CardListItem width="w-full">
          <p>
            All presenting authors of accepted abstracts are expected to
            register to attend the conference and present at a scheduled
            session.
          </p>
        </CardListItem>
        <CardListItem width="w-full">
          <p>
            Abstract submissions for all presentations must be{" "}
            <b>between 300 and 500 words</b> in length.
          </p>
        </CardListItem>
        <CardListItem width="w-full">
          <p>Abstracts MUST include a description of each of the following:</p>
          <ul className="ml-8 list-disc">
            <li className={"mt-2"}>The research focus or problem/intervention program.</li>
            <li className={"mt-2"}>
              The conceptual framework that informed the research or the
              initiative implemented to address the problem/intervention.
            </li>
            <li className={"mt-2"}>
              Research methods, including ethics or permissions needed to run
              the problem/intervention program.
            </li>
            <li className={"mt-2"}>
              Key insights or findings and implications for the future we want
              in computing.
            </li>
          </ul>
        </CardListItem>
        <CardListItem width="w-full">
          <p>
            Authors are advised to use the modified{" "}
            <a
              href="https://nationalueduph-my.sharepoint.com/:w:/g/personal/aotrillanes_national-u_edu_ph/EcoQSoiB4b5Ov6N9kUa37j0BAfcpjgNhI6EtAumkMDVzMw?download=1"
              download
              className="text-accent hover:text-primary-light"
            >
              ACM SIGHI Extended Abstract
            </a>{" "}
            for their submissions.
          </p>
        </CardListItem>
        <CardListItem width="w-full">
          <p>
            Abstracts should be submitted through EasyChair.{" "}
            <a
              href="https://easychair.org/conferences/?conf=acmwap2021"
              target="_blank"
              rel="noreferrer"
              className="text-accent hover:text-primary-light"
            >
              (Link)
            </a>
          </p>
        </CardListItem>
      </div>
    </SectionWrapper>
  </div>
)

export default Submission
