import React from "react"
import SectionWrapper from "./section-wrapper"
import SectionTitle from "./section-title"

import CardListItem from "./card-list-item"

const Abstract = () => (
    <div className="flex items-center" id="abstracts">
        <SectionWrapper padding="px-6 md:px-16 pt-16 md:pt-32 pb-16">
            <SectionTitle textSize="text-3xl">Call for Abstracts</SectionTitle>
            <p className="mt-1">Extended abstracts are invited in relation to the celebration theme and sub-themes of:</p>
            <div className="mt-4 flex flex-wrap items-stretch">
                <CardListItem width="w-full md:w-1/2">
                    <p>Increasing diversity in computing courses and careers</p>
                </CardListItem>
                <CardListItem width="w-full md:w-1/2">
                    <p>Encouraging students to consider computing in their future</p>
                </CardListItem>
                <CardListItem width="w-full md:w-1/2">
                    <p>Recruitment and retention of girls and women in computing courses and careers</p>
                </CardListItem>
                <CardListItem width="w-full md:w-1/2">
                    <p>Initiatives that explore the contribution of computing to society</p>
                </CardListItem>
                <CardListItem width="w-full md:w-1/2">
                    <p>Computing for social impact and creativity</p>
                </CardListItem>
            </div>
        </SectionWrapper>
    </div>
)

export default Abstract