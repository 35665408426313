import React from "react"
import SectionWrapper from "./section-wrapper"
import SectionTitle from "./section-title"
import CardListItem from "./card-list-item"

const PosterAndVideos = () => (
  <div className="flex items-center bg-light" id="poster-and-videos">
    <SectionWrapper padding="px-6 md:px-16 py-16">
      <SectionTitle textSize="text-3xl">Posters and Video Presentations</SectionTitle>
      <div className="mt-4 flex flex-wrap items-stretch">
        <CardListItem width="w-full">
          <p>
            All accepted abstracts will be required to prepare a digital poster and a <b>5-minute video presentation</b>. All submitted posters and video presentations will be displayed on the ACM-W AP Celebration 2021 website. Each poster will appear on its page, with a section for a pre-recorded video presentation by the author/s. A virtual forum will be embedded on each page for the Q&A.
          </p>
        </CardListItem>
        <CardListItem width="w-full">
          <p>
            Selected abstracts will be included for presentation during the program on April 7, 2021, under Presentation Session. The Programs Committee will make the selection of abstracts for presentation.
          </p>
        </CardListItem>
        <CardListItem width="w-full">
          <p>
            The ACM-W AP encourages all presenters to make time to design posters and videos that will get your messages across the delegates and attendees effectively.
          </p>
        </CardListItem>
        <Poster />
        <VideoPresentation />
        <PosterVideoSubmission />
      </div>
    </SectionWrapper>
  </div>
)

const Poster = () => (
  <>
    <h2 className="text-xl font-bold mt-8 mb-4" data-sal="fade" data-sal-easing="ease" data-sal-duration="600">Poster</h2>
    <CardListItem width="w-full">
      <p>Poster specification:</p>
      <ul className="ml-8 list-disc">
        <li className={"mt-2"}>Poster Size: 4ft x 3ft</li>
        <li className={"mt-2"}>
          {`Filename: <PaperID_Author'sLastNameFirstName>.jpg (e.g., PaperID1_DelaCruzJane.jpg)`}
        </li>
        <li className={"mt-2"}>
          File Format: JPG
        </li>
        <li className={"mt-2"}>
          {`File Size: < 5MB`}
        </li>
      </ul>
    </CardListItem>
  </>
)

const VideoPresentation = () => (
  <>
    <h2 className="text-xl font-bold mt-8 mb-4" data-sal="fade" data-sal-easing="ease" data-sal-duration="600">Video Presentation</h2>
    <CardListItem width="w-full">
      <p>
        The video can be designed to embed a full-screen screencast of the slides, with a picture-in-picture video of the presenter, a full screen of slides with audio of speaker or slides, and speaker video side-by-side. You may refer to a&nbsp;
        <a href="https://medium.com/sigchi/a-remote-video-presentation-guide-93957c63aa7a" className="text-accent hover:text-primary-light" >
          Remote Video Presentation Guide
        </a>{" "} for more details.
      </p>
    </CardListItem>
    <CardListItem width="w-full">
      <p>
        Your opening title should include the authors of the research paper, the presenter's name, the affiliation, and the title of the research.
      </p>
    </CardListItem>
    <CardListItem width="w-full">
      <p>
        It is recommended to provide captions and subtitles in your video presentation to promote accessibility. The captions/subtitles do not necessarily have to stick to the voice-over. Importantly, the video must be understandable without sound.
      </p>
    </CardListItem>
    <CardListItem width="w-full">
      <p>
        Refrain from including video effects that can trigger an adverse reaction, such as flashing lights, loud sounds, or too much animation that may induce seizures for people with photosensitive epilepsy.
      </p>
    </CardListItem>
    <CardListItem width="w-full">
      <p>Video presentation specification:</p>
      <ul className="ml-8 list-disc">
        <li className={"mt-2"}>Video Length/Duration: Five (5) minutes</li>
        <li className={"mt-2"}>Resolution: 1280 x 720 (720p)</li>
        <li className={"mt-2"}>Encoding: MP4 using the H.264 codec.</li>
        <li className={"mt-2"}>Aspect Ratio: 16:9</li>
        <li className={"mt-2"}>{`File size: < 100MB`}</li>
        <li className={"mt-2"}>{`Filename: <PaperID_Author'sLastNameFirstName>.mp4 (e.g., PaperID1_DelaCruzJane.mp4`}</li>
      </ul>
    </CardListItem>
  </>
)

const PosterVideoSubmission = () => (
  <>
    <h2 className="text-xl font-bold mt-8 mb-4" data-sal="fade" data-sal-easing="ease" data-sal-duration="600">Submission</h2>
    <p className={"mb-4"}>You have two (2) options in submitting your digital poster and video presentation to the ACM-W AP Celebration 2021 Technical Committee on or before MARCH 29, 2021 (Monday).</p>
    <CardListItem width="w-full">
      <p>Email to <a href={"mailto:acmwap2021@national-u.edu.ph"} className="text-accent hover:text-primary-light">acmwap2021@national-u.edu.ph</a></p>
      <ul className="ml-8 list-disc">
        <li className={"mt-2"}>Subject: PaperID Author's LastNameFirstName (e.g., PaperID1 Dela Cruz Jane)</li>
        <li className={"mt-2"}>Provide the download link to your poster and video presentation</li>
      </ul>
    </CardListItem>
    <CardListItem width="w-full">
      <p>Upload the files in <a href={"http://bit.ly/ACMWAP_Upload"} className="text-accent hover:text-primary-light">http://bit.ly/ACMWAP_Upload</a></p>
      <ul className="ml-8 list-disc">
        <li className={"mt-2"}>Create a subfolder with PaperID Author'sLastNameFirstName (e.g., PaperID1 DelaCruzJane) as the folder name</li>
        <li className={"mt-2"}>Upload your poster and video presentation in the subfolder</li>
      </ul>
    </CardListItem>
  </>
)


export default PosterAndVideos