import React from "react"
import CardListItem from "./card-list-item"
import SectionTitle from "./section-title"
import SectionWrapper from "./section-wrapper"

const Registration = () => (
    <div className="flex items-center bg-light" id="registration">
        <SectionWrapper padding="px-8 md:px-16 py-16">
            <SectionTitle textSize="text-3xl">Registration</SectionTitle>
            <div className="mt-4 flex flex-wrap items-stretch">
                <CardListItem width="w-full">
                    <p>All presenting authors of accepted abstracts are expected to register to attend the conference. Registration is free of charge.</p>
                    <br />
                    <p>Registration link can be found here <a className="text-accent hover:text-primary-light" href="http://bit.ly/RegistrationACMWAP" target="_blank" rel="noreferrer">(Link)</a></p>
                    <br />
                    <p>Further inquiries can be sent to: <u>acmwap2021@national-u.edu.ph</u></p>
                </CardListItem>
            </div>
        </SectionWrapper>
    </div>
)

export default Registration