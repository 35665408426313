import React from "react"
import SectionWrapper from "./section-wrapper"
import SectionTitle from "./section-title"

import CardListItem from "./card-list-item"

const Presentation = () => (
    <div className="flex items-center" id="presentation">
        <SectionWrapper padding="px-8 md:px-16 py-16">
            <SectionTitle textSize="text-3xl">Presentation Formats</SectionTitle>
            <p className="mt-1">There will be two types of presentations.</p>
            <div className="mt-4 flex flex-wrap items-stretch">
                <CardListItem width="w-full md:w-1/2">
                    <p>Oral Presentation</p>
                </CardListItem>
                <CardListItem width="w-full md:w-1/2">
                    <p>Poster Presentation</p>
                </CardListItem>
            </div>
            <p className="mt-1">Accepted abstracts will be published in an online proceedings booklet after the Celebration event.</p>
        </SectionWrapper>
    </div>
)

export default Presentation